function gtag_report_live_chat(url) {
    var callback = function () {
        if (typeof(url) != 'undefined')

        {       window.location = url;     }
    };
    gtag('event', 'conversion',
        {
            'send_to': 'AW-942443869/IEWiCKGkmH8Q3ZqywQM',
            'event_callback': callback
        }
    );
    return false;
}

(function($) {

    if (typeof $ === 'undefined') {
        return;
    }

    var Zopimmed = false;

    $(window).on('load', function () {
        $('.zopim iframe').on('click', function() {
            if(Zopimmed === false) {
                //gtag_report_live_chat();
                $zopim(function(){
                    $zophim.livechat.setOnChatStart(gtag_report_live_chat());
                });
                Zopimmed = true;
            }
        })
    });

})(jQuery);
