(function($) {

    if (typeof $ === 'undefined') {
        return;
    }

    $(document).ready(function() {
        $('.twitter').uniformify({
            selector: '.tweet *'
        });
    });

    $(document).ready(function() {
        $('.grid-items').uniformify({
            selector: '.grid-item .grid-text'
        });
    });

    $(document).ready(function() {
        $('.nav-expand').on('click', function(e) {
            e.preventDefault();
            e.stopPropagation();
            $('.nav li:not(.no-hide)').toggleClass('hidden');
        });
        $('.nav li:not(.logo)').addClass('hidden');
    });

    $(document).ready(function() {
        $('.subnav-button').on('click', function(e) {
            e.preventDefault();
            e.stopPropagation();
            $('.subnav li:not(.no-hide)').toggleClass('hidden');
        });
        $('.subnav li:not(.logo)').addClass('hidden');
    });

    $(document).ready(function() {
        $('.gallery .gallery-image').each(function(e) {
            var here = $(this);
            console.log(here);
            here.append('<span class="image-caption">'+$('img', here).attr("alt")+'</span>');
        })
   });


})(jQuery);
